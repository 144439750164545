import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { ulid } from 'ulid';
import { findIndex, omit } from 'lodash';
import { PaystackProps } from 'react-paystack/libs/types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { Address, PaystackCustomProps, Profile, Status } from '../typings';
import { AuthStateContext, AuthStatus } from './AuthContext';
import {
  ADD_ADDRESS,
  ADD_PROFILE, DOWNGRADE_SUBSCRIPTION,
  REMOVE_PROFILE,
  UPDATE_ADDRESS,
  UPDATE_PROFILE,
  UPGRADE_SUBSCRIPTION
} from '../graphql/mutations';
import { GraphqlUtils, GraphqlUtilsInterface } from '../libs/graphqlUtils';
import { profileList } from '../graphql/queries';
import * as hadesPlans from '../config/cronpay';

const gqlUtils: GraphqlUtilsInterface = GraphqlUtils();

export const DEFAULT_PROFILE_PRICE = 399;
export const DEFAULT_CHILD_PROFILE_PRICE = 199;

export const getPrice = (profile?: Profile): number => {
  if (profile?.age === 'child') {
    return DEFAULT_CHILD_PROFILE_PRICE;
  }

  return DEFAULT_PROFILE_PRICE;
};

export const getNonChildProfiles = (profiles: Profile[]): Profile[] =>
  profiles.filter((profile) => profile.age !== 'child');

export const getChildProfiles = (profiles: Profile[]): Profile[] =>
  profiles.filter((profile) => profile.age === 'child');

export const discounts = [
  0, // 1x
  5, // 2x
  10, // 3x
  15, // 4x
  20, // 5x
  25 // 6x
];

export const calculateItemDiscount = (
  index: number,
  profile?: Profile
): { name: string; value: number; price: number } => {
  const level: number = index < 6 ? index : 5;

  // Accommodate child profiles
  if (profile?.age === 'child') {
    return {
      name: `0%`,
      value: 0,
      price: DEFAULT_CHILD_PROFILE_PRICE
    };
  }

  return {
    name: `${discounts[level]}%`,
    value: discounts[level],
    price: Math.floor(DEFAULT_PROFILE_PRICE * (1 - discounts[level] / 100))
  };
};

export const calculateTotalPrice = (profiles: any[]): number => {
  if (profiles.length <= 0) {
    return 0;
  }

  let total = 0;

  // Adult and teen profiles
  Object.keys(getNonChildProfiles(profiles)).forEach((key, index) => {
    total += calculateItemDiscount(index).price;
  });

  // Child profiles
  total += getChildProfiles(profiles).length * DEFAULT_CHILD_PROFILE_PRICE;

  return total;
};

export type ShopStateContextType = {
  address: Address;
  profiles: Profile[];
  isCartOpen: boolean;
  hasInitiated: boolean;
  nextDiscount: number;
  loadingAddress: boolean;
  processingProfileOnIndex: boolean;
  promoCode?: string;
  transactionRef?: string;
  referrer?: string;
  referralId?: string;
};

export type ShopDispatchContextType = {
  addProfile: (profile: Profile) => Promise<void>;
  updateProfile: (profile: Profile) => Promise<void>;
  upgradeSubscription: (profile: Profile, subscriptionId: string) => Promise<any>;
  downgradeSubscription: (profileId: string, subscriptionId: string) => Promise<any>;
  removeProfile: (id: string) => Promise<void>;
  addAddress: (address: Address) => Promise<void>;
  updateAddress: (address: Address) => Promise<void>;
  buildPaystackButtonProps: ({ address }: { address: Address }) => PaystackProps;
  dispatch: (action: any) => void;
};
type ShopContextProps = {
  children: React.ReactNode;
  initialState?: Partial<ShopStateContextType>;
};

function shopReducer(draft: any, action: any) {
  switch (action.type) {
    case 'addingAddress':
    case 'gettingAddress': {
      draft.loadingAddress = true;
      break;
    }
    case 'addAddress': {
      draft.address = action.address;
      draft.loadingAddress = false;
      break;
    }
    case 'updateAddress': {
      draft.address = action.address;
      draft.loadingAddress = false;
      break;
    }
    case 'toggleCartOpen': {
      draft.isCartOpen = !draft.isCartOpen;
      break;
    }
    case 'setProfiles': {
      if (action.hasInitiated) {
        draft.hasInitiated = action.hasInitiated;
      }
      draft.profiles = action.profiles;
      draft.nextDiscount = discounts[getNonChildProfiles(draft.profiles).length];
      break;
    }
    case 'setPromoCode': {
      draft.promoCode = action.promoCode;
      break;
    }
    case 'addingProfile':
    case 'updatingProfile':
    case 'removingProfile': {
      draft.processingProfileOnIndex = action.index;
      break;
    }
    case 'addProfile': {
      draft.profiles.push(action.profile);
      draft.nextDiscount = discounts[getNonChildProfiles(draft.profiles).length];
      draft.processingProfileOnIndex = undefined;
      draft.isCartOpen = true;
      break;
    }
    case 'updateProfile': {
      if (action.index) {
        draft.profiles[action.index] = action.profile;
      }
      draft.processingProfileOnIndex = undefined;
      break;
    }
    case 'removeProfile': {
      draft.profiles.splice(action.index, 1);
      draft.nextDiscount = discounts[getNonChildProfiles(draft.profiles).length];
      draft.processingProfileOnIndex = undefined;
      break;
    }
    case 'checkoutSuccess': {
      draft.profiles = [];
      draft.nextDiscount = discounts[getNonChildProfiles(draft.profiles).length];
      draft.promoCode = undefined;
      draft.transactionRef = action.transactionRef;
      break;
    }
    case 'clearCart': {
      draft.profiles = [];
      draft.nextDiscount = discounts[getNonChildProfiles(draft.profiles).length];
      draft.promoCode = undefined;
      break;
    }
    case 'setReferrer': {
      draft.referrer = action.referrer;
      draft.referralId = action.referralId || null;
      break;
    }
    default: {
      throw new Error(`Unhandled dispatch: ${action.type}`);
    }
  }
}

export const ShopStateContext = createContext({} as ShopStateContextType);
export const ShopDispatchContext = createContext({} as ShopDispatchContextType);

const defaultInitialState = {
  isCartOpen: false,
  profiles: [],
  hasInitiated: false
};

export function ShopProvider({ children, initialState = defaultInitialState }: ShopContextProps): any {
  const [state, dispatch] = useImmerReducer<any, any>(shopReducer, initialState);
  const { isAuthenticated, isLoading, user, sessionStatus } = useContext(AuthStateContext);
  const { profiles, hasInitiated, promoCode, referrer, referralId } = state;
  const stage = process.env.NEXT_PUBLIC_STAGE;
  const [addProfileQ] = useMutation(ADD_PROFILE);
  const [upgradeSubscriptionQ] = useMutation(UPGRADE_SUBSCRIPTION);
  const [downSubscriptionQ] = useMutation(DOWNGRADE_SUBSCRIPTION);
  const [updateProfileQ] = useMutation(UPDATE_PROFILE);
  const [addAddressQ] = useMutation(ADD_ADDRESS);
  const [updateAddressQ] = useMutation(UPDATE_ADDRESS);
  const [removeProfileQ] = useMutation(REMOVE_PROFILE);
  const [getProfileList, { data: profileData, called }] = useLazyQuery(profileList, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });
  const router = useRouter();
  const [queryParams] = useState(new URLSearchParams(router.asPath.split('?')[1]));

  // Apply promotional discount to plan amount
  const addPromo = (code: string, amount: number): number => {
    if (!code) {
      return amount;
    }

    return amount - 10000; // Default R100.00 off (Paystack uses cents)
  };

  // Save referrer and referralId from URL query params
  useEffect(() => {
    const referralQueryParams = {
      referrer: queryParams.get('referrer'),
      referralId: queryParams.get('referralId')
    };

    if (!referralQueryParams.referrer) {
      return;
    }

    dispatch({
      type: 'setReferrer',
      referrer: referralQueryParams.referrer,
      referralId: referralQueryParams.referralId
    });
  }, [queryParams, router.isReady]);

  // Fetch user data (once) when logged in
  useEffect(() => {
    if (isAuthenticated && !isLoading && !called) {
      // Create localstorage profiles under user account
      if (localStorage.profiles) {
        const tmpProfiles = JSON.parse(localStorage.profiles);
        const promises: Promise<any>[] = [];

        // Create remote profiles
        tmpProfiles.forEach(async (localProfile) => {
          const tmpProfile = { ...omit(localProfile, ['id', 'created', 'modified', 'error']), status: Status.PENDING };

          promises.push(
            addProfileQ({
              variables: { input: gqlUtils.prepGQLObject(tmpProfile) },
              notifyOnNetworkStatusChange: true
            })
          );
        });

        // Only fetch profiles list after all local profiles were added.
        Promise.all(promises).then(() => {
          getProfileList();

          // Remove local profiles
          localStorage.removeItem('profiles');
        });
      } else {
        getProfileList();
      }
    }
  }, [isAuthenticated, isLoading, called, addProfileQ, getProfileList]);

  // Load profiles if any exist on remote. NB: Don't add auth state vars as that would create state bleeding.
  useEffect(() => {
    if (profileData) {
      dispatch({
        type: 'setProfiles',
        profiles: profileData?.profileList?.results.filter((p) => p.status === Status.PENDING) || [],
        hasInitiated: true
      });
    }
  }, [dispatch, profileData]);

  // Clean localstorage after sign out
  useEffect(() => {
    if (sessionStatus === AuthStatus.PENDING_SIGN_OUT) {
      localStorage.removeItem('profiles');
      dispatch({ type: 'clearCart' });
    }
  }, [dispatch, sessionStatus]);

  // Not authenticated storing of profiles to localstorage
  useEffect(() => {
    // If not authenticated, check localstorage for profiles
    if (!hasInitiated) {
      dispatch({
        type: 'setProfiles',
        profiles: localStorage.profiles ? JSON.parse(localStorage.profiles) : [],
        hasInitiated: true
      });

      return;
    }

    if (
      sessionStatus === AuthStatus.ANONYMOUS &&
      !isAuthenticated &&
      hasInitiated &&
      !isLoading &&
      profiles &&
      JSON.stringify(profiles) !== localStorage.profiles
    ) {
      localStorage.setItem('profiles', JSON.stringify(profiles));
    }
  }, [isAuthenticated, isLoading, profiles, hasInitiated, dispatch, sessionStatus]);

  const addProfile = useCallback(
    async (newProfile: Profile): Promise<void> => {
      if (!isAuthenticated) {
        dispatch({ type: 'addProfile', profile: { id: ulid(), ...newProfile } });
      }

      if (isAuthenticated) {
        try {
          const resp = await addProfileQ({
            variables: { input: gqlUtils.prepGQLObject({ ...newProfile, status: Status.PENDING }) }
          });

          dispatch({ type: 'addProfile', profile: { id: ulid(), ...resp?.data.addProfile } });
        } catch (err: any) {
          console.error({ action: 'Could not sync new profile', error: err.message });

          // Re-throw error to catch it downstream
          throw err;
        }
      }
    },
    [addProfileQ, dispatch, isAuthenticated]
  );

  const upgradeSubscription = useCallback(
    async (newProfile: Profile, subscriptionId: string): Promise<any> => {
      // Add new profile
      const { data } = await addProfileQ({
        variables: { input: gqlUtils.prepGQLObject({ ...newProfile, status: Status.PENDING }) }
      });

      // Upgrade subscription
      await upgradeSubscriptionQ({
        variables: { input: gqlUtils.prepGQLObject({ profileId: data.addProfile.id, subscriptionId }) }
      });

      return data.addProfile;
    },
    [addProfileQ]
  );

  const downgradeSubscription = useCallback(
    async (profileId: string, subscriptionId: string): Promise<any> => {
      // Upgrade subscription
      const resp = await downSubscriptionQ({
        variables: { input: gqlUtils.prepGQLObject({ profileId, subscriptionId }) }
      });

      return resp;
    },
    [addProfileQ]
  );

  const updateProfile = useCallback(
    async (newProfile: Profile): Promise<void> => {
      if (!isAuthenticated) {
        dispatch({ type: 'updateProfile', profile: { id: ulid(), ...newProfile } });
      }

      if (isAuthenticated) {
        try {
          const resp = await updateProfileQ({
            variables: { input: gqlUtils.prepGQLObject({ ...newProfile }) }
          });

          dispatch({ type: 'updateProfile', profile: { ...resp?.data.updateProfile } });
        } catch (err: any) {
          console.error({ action: 'Could not update profile', error: err.message });

          // Re-throw error to catch it downstream
          throw err;
        }
      }
    },
    [dispatch, isAuthenticated, updateProfileQ]
  );

  const removeProfile = useCallback(
    async (id: string): Promise<void> => {
      const index = findIndex(profiles, ['id', id]);
      if (!isAuthenticated) {
        dispatch({ index, type: 'removeProfile' });
        return;
      }

      dispatch({ index, type: 'removingProfile' });
      if (isAuthenticated) {
        try {
          await removeProfileQ({
            variables: { input: gqlUtils.prepGQLObject({ id }) }
          });
          dispatch({ index, type: 'removeProfile' });
        } catch (err: any) {
          console.error({ action: 'Could not remove profile', error: err.message });

          // Re-throw error to catch it downstream
          throw err;
        }
      }
    },
    [dispatch, isAuthenticated, profiles, removeProfileQ]
  );

  const addAddress = useCallback(
    async (newAddress: Address): Promise<void> => {
      try {
        const resp = await addAddressQ({
          variables: {
            input: gqlUtils.prepGQLObject({
              ...newAddress,
              status: Status.PENDING
            })
          }
        });

        dispatch({ type: 'addAddress', address: resp?.data.addAddress });
      } catch (err: any) {
        console.error({ action: 'Could not add address', error: err.message });

        // Re-throw error to catch it downstream
        throw err;
      }
    },
    [addAddressQ, dispatch]
  );

  const updateAddress = useCallback(
    async (newAddress: Address): Promise<void> => {
      const resp = await updateAddressQ({
        variables: { input: gqlUtils.prepGQLObject({ ...newAddress }) }
      }).catch((err) => {
        console.error({ action: 'Could not update address', error: err.message });
      });

      dispatch({ type: 'updateAddress', address: resp?.data.updateAddress });
    },
    [dispatch, updateAddressQ]
  );

  const buildPaystackButtonProps = useCallback(
    ({ address }: { address: Address }): PaystackProps => {
      if (!user) {
        throw new Error('User session required for checkout');
      }
      // issue
      if (profiles.length < 1) {
        throw new Error('Profiles are required for checkout');
      }
      if (!address) {
        throw new Error('A shipping address is required for checkout');
      }

      const plan = `0${getNonChildProfiles(profiles).length}${getChildProfiles(profiles).length}`;
      const addressStr = [address.suite, address.street, address.city, address.state, address.zip, address.country]
        .filter((a) => !!a)
        .join(', ');

      const props: Partial<PaystackCustomProps> = {
        email: user.email.toLowerCase(),
        publicKey: process.env.NEXT_PUBLIC_PAYSTACK_PUBLIC_KEY || '',
        currency: 'ZAR',
        // Paystack amount should be in cents
        amount: addPromo(promoCode, hadesPlans[stage === 'prod' ? 'prod' : 'dev'][plan].amount * 100),
        // https://paystack.com/docs/payments/metadata/#crafting-metadata
        metadata: {
          /* If you need to directly debit the customer in the future, specify recurring=true under this object to ensure
             we accept only verve cards that support recurring billing. And force a bank authentication for MasterCard
             and VISA. */
          recurring: true,
          createSubscription: true,
          planId: hadesPlans[stage === 'prod' ? 'prod' : 'dev'][plan].id,
          addressId: address.id as string,
          profileIds: profiles.filter((p) => p.status !== Status.REMOVED).map((p) => p.id),
          userId: user.id,
          custom_fields: [
            {
              display_name: 'Shipping Address',
              variable_name: 'address',
              value: `${addressStr}, ${address.id as string}`
            },
            {
              display_name: 'Profile IDs',
              variable_name: 'profileIds',
              value: profiles
                .filter((p) => p.status !== Status.REMOVED)
                .map((p) => p.id)
                .join(', ')
            },
            {
              display_name: 'Profile combinations',
              variable_name: 'profileCombinations',
              value: profiles
                .filter((p) => p.status !== Status.REMOVED)
                .map((p) => p.profileCombination)
                .join(', ')
            }
          ]
        }
      };

      if (user.fName) {
        props.firstname = user.fName;
      }

      if (user.lName) {
        props.lastname = user.lName;
      }

      if (user.phone) {
        props.phone = user.phone;
      }

      if (props.metadata && promoCode) {
        props.metadata.promoCode = promoCode.toLowerCase();
      }

      // Add referrer to payments
      if (props.metadata && referrer) {
        props.metadata.referralCode = referrer; // Fallback as referrer gets overridden by Paystack internal lib
        props.metadata.referrer = referrer;
        props.metadata.custom_fields.push({
          display_name: 'Referrer',
          variable_name: 'referrer',
          value: referrer
        });

        if (referralId) {
          props.metadata.referralId = referralId;
          props.metadata.custom_fields.push({
            display_name: 'Referral ID',
            variable_name: 'referralId',
            value: referralId
          });
        }
      }

      return props as PaystackProps;
    },
    [profiles, stage, user, promoCode, referrer]
  );

  const dispatchContextValue = useMemo(
    () => ({
      addProfile,
      updateProfile,
      removeProfile,
      addAddress,
      updateAddress,
      upgradeSubscription,
      downgradeSubscription,
      buildPaystackButtonProps,
      dispatch
    }),
    [addProfile, updateProfile, removeProfile, addAddress, updateAddress, buildPaystackButtonProps, dispatch]
  );

  return (
    <ShopStateContext.Provider value={state}>
      <ShopDispatchContext.Provider value={dispatchContextValue}>{children}</ShopDispatchContext.Provider>
    </ShopStateContext.Provider>
  );
}

export const useShopState = () => {
  const c = useContext(ShopStateContext);
  if (!c) throw new Error('Cannot use useShopState when not under the ShopProvider');
  return c;
};

export const useShopDispatch = () => {
  const c = useContext(ShopDispatchContext);
  if (!c) throw new Error('Cannot use useShopDispatch when not under the ShopProvider');
  return c;
};
